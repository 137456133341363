
import { IGameData, IFeedbackData, IIntroTxt } from './types'
import requestData from '../utils/requestData'
import { AxiosPromise } from 'axios'

export const GamesData = () => 
    requestData(
        {
            url: '/data/home/games_data.json',
            method: 'get'
        }
    ) as AxiosPromise<IGameData[]>


export const FeedbacksData: Array<IFeedbackData> = [
    {
        name: 'Stinkie Feet',
        imgUrl: 'img/faces/face1.png',
        feedback: 'I like this game very much stop playing it just started playing it today thanks for the company who created it well done!',
        gameName: 'Merge Dice'
    },
    {
        name: 'Sandy62676',
        imgUrl: 'img/faces/face2.png',
        feedback: 'I love this game. So much fun.',
        gameName: 'Merge Dice'
    },
    {
        name: 'sholiz64',
        imgUrl: 'img/faces/face3.png',
        feedback: 'I just love this puzzle can’t stop playing its so much fun.',
        gameName: 'Merge Dice'
    },
    {
        name: 'GPC49ers',
        imgUrl: 'img/faces/face4.png',
        feedback: 'Fun and addictive game!',
        gameName: 'NINES! Purple'
    },
    {
        name: 'HardKnoz',
        imgUrl: 'img/faces/face5.png',
        feedback: 'This is a great Game for people that are getting older. It makes you use your brain to remember and concentrate. Love it thank you',
        gameName: 'NINES! Purple'
    },
    {
        name: 'Shishioghen',
        imgUrl: 'img/faces/face6.png',
        feedback: 'I can spend my whole day practice this puzzle! Very relax but challenge enough.',
        gameName: 'NINES! Purple'
    },
    {
        name: 'Barbie',
        imgUrl: 'img/faces/face7.png',
        feedback: 'Didn’t care for the game at first, but once I got the hang of it I became addicted!',
        gameName: 'Cookie Slide'
    },
    {
        name: 'Franky Willy',
        imgUrl: 'img/faces/face8.png',
        feedback: 'Good game to play.',
        gameName: 'PairScapes'
    }
]

export const IntroTxts = () => 
        requestData(
            {
                url: '/data/home/intros.json',
                method: 'get'
            }
        ) as AxiosPromise<IIntroTxt[]>

export const teamBenefit = () => 
    requestData(
        {
            url: '/data/careers/team_benefit.json',
            method: 'get'
        }
    )

export const currentJobs = () => 
    requestData(
        {
            url: '/data/careers/current_jobs.json',
            method: 'get'
        }
    )

export const jobDetail = (fileName: string) => 
    requestData(
        {
            url: `/data/careers/job_detail/${fileName}`,
            method: 'get'
        }
    )