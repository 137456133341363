












































import Vue from 'vue'
import { Component,Prop } from 'vue-property-decorator'
import BaseButton from '@/components/BaseButton.vue'
import { IIntroTxt } from '../../../api/types'
import { IntroTxts } from '@/api/data'

@Component({
    name: 'Intro',
    components: {
      BaseButton
    }
})
export default class extends Vue {
  @Prop({default: '"/img/office.jpg"', type: String}) imgSrc!: string
  @Prop({default: 900, type: Number}) height!: number
  @Prop({default: true, type: Boolean}) showInfos!: boolean
// 'careers-page'

  private pagesData: Array<IIntroTxt> = [{txt: ""}]
  private pageId = 0

  async created() {
    this.pagesData = await IntroTxts() as any
  }

  async mounted() {
    setInterval(() => {
      this.onChangePageClicked(1)
    }, 3000) 
  }

  onChangePageClicked(pageChange: number) {
    if(this.pageId + pageChange >= this.pagesData.length) {
      this.pageId = 0
    } else if(this.pageId + pageChange < 0) {
      this.pageId = this.pagesData.length - 1
    } else {
      this.pageId += pageChange
    }
  }
}

